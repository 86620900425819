import { SupportedCurrencyCodes } from "../hooks/useCurrency";
import { type MembershipTier } from "../server/api/routers/paymentsRouter";

export type SubscriptionPricingVersion = "v1or2" | "v3";

export const getPriceForCurrency = (
  currencyCode: SupportedCurrencyCodes,
  pricingVersion: SubscriptionPricingVersion
) => {
  switch (pricingVersion) {
    case "v1or2":
      if (currencyCode === SupportedCurrencyCodes.USD) return "$39";
      if (currencyCode === SupportedCurrencyCodes.GBP) return "£34";
      if (currencyCode === SupportedCurrencyCodes.EUR) return "€38";
      if (currencyCode === SupportedCurrencyCodes.BRL) return "R$179";
      if (currencyCode === SupportedCurrencyCodes.AUD) return "A$64";
      if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$52";
      if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 294";
      if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 445";
      if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 445";
      break;
    case "v3":
      if (currencyCode === SupportedCurrencyCodes.USD) return "$75";
      if (currencyCode === SupportedCurrencyCodes.GBP) return "£58";
      if (currencyCode === SupportedCurrencyCodes.EUR) return "€69";
      if (currencyCode === SupportedCurrencyCodes.BRL) return "R$432";
      if (currencyCode === SupportedCurrencyCodes.AUD) return "A$114";
      if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$104";
      if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 522";
      if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 825";
      if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 810";
      break;
    default:
      throw new Error("Invalid pricing version");
  }
};

export const getGoldTierPriceForCurrency = (
  currencyCode: SupportedCurrencyCodes,
  pricingVersion: SubscriptionPricingVersion
) => {
  switch (pricingVersion) {
    case "v1or2":
      if (currencyCode === SupportedCurrencyCodes.USD) return "$69";
      if (currencyCode === SupportedCurrencyCodes.GBP) return "£54";
      if (currencyCode === SupportedCurrencyCodes.EUR) return "€59";
      if (currencyCode === SupportedCurrencyCodes.BRL) return "R$299";
      if (currencyCode === SupportedCurrencyCodes.AUD) return "A$99";
      if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$92";
      if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 468";
      if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 699";
      if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 699";
      break;
    case "v3":
      if (currencyCode === SupportedCurrencyCodes.USD) return "$99";
      if (currencyCode === SupportedCurrencyCodes.GBP) return "£77";
      if (currencyCode === SupportedCurrencyCodes.EUR) return "€93";
      if (currencyCode === SupportedCurrencyCodes.BRL) return "R$574";
      if (currencyCode === SupportedCurrencyCodes.AUD) return "A$150";
      if (currencyCode === SupportedCurrencyCodes.CAD) return "CA$138";
      if (currencyCode === SupportedCurrencyCodes.DKK) return "DKK 694";
      if (currencyCode === SupportedCurrencyCodes.NOK) return "NOK 1 100";
      if (currencyCode === SupportedCurrencyCodes.SEK) return "SEK 1 080";
      break;
    default:
      throw new Error("Invalid pricing version");
  }
};

export const priceAndCurrencyMap: Record<
  MembershipTier,
  Record<SupportedCurrencyCodes, { amount: number; currencySymbol: string }>
> = {
  silver: {
    [SupportedCurrencyCodes.USD]: {
      amount: 39,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 34,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 38,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 179,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 64,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 52,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 294,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 445,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 445,
      currencySymbol: "SEK ",
    },
  },
  gold: {
    [SupportedCurrencyCodes.USD]: {
      amount: 69,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 54,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 59,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 299,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 99,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 92,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 468,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 699,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 699,
      currencySymbol: "SEK ",
    },
  },
  silverV3: {
    [SupportedCurrencyCodes.USD]: {
      amount: 75,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 58,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 69,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 432,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 114,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 104,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 522,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 825,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 810,
      currencySymbol: "SEK ",
    },
  },
  goldV3: {
    [SupportedCurrencyCodes.USD]: {
      amount: 99,
      currencySymbol: "$",
    },
    [SupportedCurrencyCodes.GBP]: {
      amount: 77,
      currencySymbol: "£",
    },
    [SupportedCurrencyCodes.EUR]: {
      amount: 93,
      currencySymbol: "€",
    },
    [SupportedCurrencyCodes.BRL]: {
      amount: 574,
      currencySymbol: "R$",
    },
    [SupportedCurrencyCodes.AUD]: {
      amount: 150,
      currencySymbol: "A$",
    },
    [SupportedCurrencyCodes.CAD]: {
      amount: 138,
      currencySymbol: "CA$",
    },
    [SupportedCurrencyCodes.DKK]: {
      amount: 694,
      currencySymbol: "DKK ",
    },
    [SupportedCurrencyCodes.NOK]: {
      amount: 1100,
      currencySymbol: "NOK ",
    },
    [SupportedCurrencyCodes.SEK]: {
      amount: 1080,
      currencySymbol: "SEK ",
    },
  },
};
